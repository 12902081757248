import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import { axios } from "@/utils/http"

// default lang
import zhCN from './lang/zh-CN'
import { APP_LANGUAGE } from "@/store/mutation-types"

Vue.use(VueI18n)

export const defaultLang = 'zh-CN'

const messages = {
  'zh-CN': {
    ...zhCN
  }
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages: messages
})

const loadedLanguages = [defaultLang]

function setI18nLanguage(lang) {
  i18n.locale = lang
  // request.headers['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function fetchI18n(i18n) {
  axios({
    url: "/ofa-admin/ofaI18n/list",
    method: "get",
    params: {
      appKey: "APP_Iy8i8KOsaVvwFdKNY"
    }
  }).then(res => {
    if (res.code === 200) {
      let data = res.data;
      for (let i = 0; i < data.length; i++) {
        let id = data[i].id;
        let message = data[i].message;
        for (let key in message) {
          i18n.mergeLocaleMessage(key, { [id]: message[key] });
        }
      }
    }
  })
}

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    // 缓存语言设置
    Vue.ls.set(APP_LANGUAGE, lang)
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        return require([`./lang/${lang}`], msg => {
          const locale = msg.default
          i18n.setLocaleMessage(lang, locale)
          loadedLanguages.push(lang)
          moment.updateLocale(locale.momentName, locale.momentLocale)
          return setI18nLanguage(lang)
        })
      }
      return resolve(setI18nLanguage(lang))
    }
    return resolve(lang)
  })
}

export function i18nRender(key) {
  return i18n.t(`${key}`)
}

export default i18n
