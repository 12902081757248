/**
 * 项目默认配置项
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */

export default {
  multiTab: true,
  title: "云平台",
  // vue-ls options
  storageOptions: {
    namespace: "zw___", // key prefix
    name: "ls", // name variable Vue.[ls] or this.[$ls],
    storage: "local" // storage name session, local, memory
  },
  recordsTable: {
    sizeOptions: ["compact", "secondary", "easy"],
    defaultSizeIndex: 1,
    sizeClassOptions: ["records-table-small", "records-table-middle", "records-table-default"],
    defaultSizeClass: "records-table-middle"
  },
  baseSubsystemId: "1234567899876543210" // 任意值不与已有子系统相同的值
}
