import { isArray, isBlank } from "./dataType";
import { APP_LANGUAGE } from "@/store/mutation-types"
import i18n from "@/locales"
import Vue from 'vue'

// 引用
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  let event = document.createEvent("HTMLEvents")
  event.initEvent("resize", true, true)
  event.eventType = "message"
  window.dispatchEvent(event)
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj (obj) {
  if (!(typeof obj === "object")) {
    return
  }

  for (var key in obj) {
    if (obj.hasOwnProperty(key) &&
      (obj[key] == null || obj[key] === undefined || obj[key] === "")) {
      delete obj[key]
    }
    // 数组转String
    if (isArray(obj[key])) {
      obj[key] = String(obj[key])
    }
  }
  return obj
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate (value, fmt) {
  var regPos = /^\d+(\.\d+)?$/
  if (regPos.test(value)) {
    // 如果是数字
    let getDate = new Date(value)
    let o = {
      "M+": getDate.getMonth() + 1,
      "d+": getDate.getDate(),
      "h+": getDate.getHours(),
      "m+": getDate.getMinutes(),
      "s+": getDate.getSeconds(),
      "q+": Math.floor((getDate.getMonth() + 3) / 3),
      "S": getDate.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
      }
    }
    return fmt
  } else {
    // TODO
    value = value.trim()
    return value.substr(0, fmt.length)
  }
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject (obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber () {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  if (arguments.length === 1) {
    let [length] = arguments
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
    return parseInt(nums.join(""))
  } else if (arguments.length >= 2) {
    let [min, max] = arguments
    return random(min, max)
  } else {
    return Number.NaN
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString (length, chats) {
  if (!length) length = 1
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm"
  let str = ""
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID () {
  let chats = "0123456789abcdef"
  return randomString(32, chats)
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase (string) {
  return string.replace(/_([a-z])/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

/**
 * 增强CSS，可以在页面上输出全局css
 * @param css 要增强的css
 * @param id style标签的id，可以用来清除旧样式
 */
export function cssExpand (css, id) {
  let style = document.createElement("style")
  style.type = "text/css"
  style.innerHTML = `@charset "UTF-8"; ${css}`
  // 清除旧样式
  if (id) {
    let $style = document.getElementById(id)
    if ($style != null) $style.outerHTML = ""
    style.id = id
  }
  // 应用新样式
  document.head.appendChild(style)
}

/**
 * 文件下载
 * @param blob response
 * @param fileName 文件名
 */
export function dowloadExcel (blob, fileName) {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement("a")
  link.style.display = "none"
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

/**
 * 获取元素到文档区域的坐标
 * @param element 网页标签元素
 */
export function getPoint (element) { // 获取某元素以浏览器左上角bai为原点的坐标
  if (element !== undefined) {
    let t = element.offsetTop; // 获取该元素对应父容器的上边距
    let l = element.offsetLeft; // 对应父容器的左边距
    // 判断是否有父容器，如果存在则累加其边距
    // eslint-disable-next-line no-cond-assign
    while (element = element.offsetParent) {
    // 等效 element = element.offsetParent;while (element != undefined)
      t += element.offsetTop; // 叠加父容器的上边距
      l += element.offsetLeft; // 叠加父容器的左边距
    }
    return { t, l };
  }
}

/**
 * 格式+搜索数据
 * @param dataSource 数据源
 * @param field 字段名
 * @param dataSource 数据源
 */
export function formatDataSource (dataSource, field, search) {
  let items = dataSource;
  let item;
  let arr = [];
  for (let i = 0; i < items.length; i++) {
    item = items[i];
    if (item.children && item.children.length > 0) {
      item.children = formatDataSource(item.children, field, search);
    } else {
      delete items[i].children;
    }
    if (!isBlank(search)) {
      if (item[field].indexOf(search) !== -1 ||
        (item.children && item.children.length > 0)) {
        arr.push(item);
      }
    } else {
      arr.push(item);
    }
  }
  return arr;
}

// 密码强度判断
export class PasswordStrength {
  constructor () {
    this.rules = [];
    this.rules.push((password) => {
      return /\d/g.test(password)
    })
    this.rules.push((password) => {
      return /[a-z]/g.test(password)
    })
    this.rules.push((password) => {
      return /[A-Z]/g.test(password)
    })
    this.rules.push((password) => {
      return /[~!@#$%^&*_./?]/g.test(password)
    })
  }
  addValidRule (rule) {
    this.rules.indexOf(rule) === -1 && this.rules.push(rule);
  }
  validatorStrength (password) {
    let level = 0;
    this.rules.forEach(function (rule) {
      rule(password) && level++;
    })
    return level;
  }
}

/**
 * 多文件打包并下载
 * @param {*} files 打包的文件数据列表
 * @param {*} filename 打包名称
 */
export function downloadPackFile (files, filename) {
  let zip = new JSZip();
  let folder = zip.folder("files");
  return new Promise((resolve, reject) => {
    Promise.resolve().then(() => {
      return files.reduce((accumulator, item) => {
        return accumulator.then(() => getArraybufferFile(item.url)
          // .then(resp => resp.blob())
          .then(blob => folder.file(item.name, blob)))
      }, Promise.resolve());
    }).then(() => {
      folder.generateAsync({ type: "blob" }).then(content => saveAs(content, filename));
      resolve(true);
    }).catch(() => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(false);
    });
  });
}

/**
 * 转化图片流格式
 * @param {*} url 图片
 * @returns
 */
export function getArraybufferFile (url) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer"
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
}

/**
 * 根据url返回文件加载地址
 * @param src 文件地址
 * @param defaultSrc 默认文件地址 || 加载src失败时使用的文件地址
 * @returns {String} 文件加载地址
 */
export function getFileSrc (src, defaultSrc) {
  if (src) {
    const reg = /^http/
    if (reg.test(src)) {
      return src
    } else {
      // try {
      return process.env.VUE_APP_OSS_DOMAIN + src
      // } catch (err) {
      //   return defaultSrc
      // }
    }
  } else {
    return defaultSrc
  }
}
// 查找祖先元素是否有某个class
export function findAncestor (el, cls) {
  while (el && (el = el.parentElement) && !el.classList.contains(cls));
  return el
}

/**
 * 获取元素到某个祖先元素的坐标,如果没有祖先元素则是文档区域的坐标
 * @param element 网页标签元素
 * @param fatherEle 目标祖先元素
 */
export function getFatherElePoint (element, fatherEle) {
  if (element !== undefined) {
    let t = element.offsetTop; // 获取该元素对应父容器的上边距
    let l = element.offsetLeft; // 对应父容器的左边距
    // 判断是否有父容器，如果存在则累加其边距
    // eslint-disable-next-line no-cond-assign
    while (element = element.offsetParent) {
      if (element === fatherEle) {
        return { t, l }
      }
      // 等效 element = element.offsetParent;while (element != undefined)
      t += element.offsetTop; // 叠加父容器的上边距
      l += element.offsetLeft; // 叠加父容器的左边距
    }
    return { t, l };
  }
}

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty (children = []) {
  return children.filter(c => c.tag || (c.text && c.text.trim() !== ""))
}

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export const getStrFullLength = (str = "") =>
  str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1
    }
    return pre + 2
  }, 0)

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export const cutStrByFullLength = (str = "", maxLength) => {
  let showLength = 0
  return str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1
    } else {
      showLength += 2
    }
    if (showLength <= maxLength) {
      return pre + cur
    }
    return pre
  }, "")
}

/* 中文简体繁体切换 */
export const changeLangType = ({ alias, aliasI18n }) => {
  // const zhCN = "zh-CN";
  const zhHK = "zh-HK";
  if (aliasI18n) {
    const lang = Vue.ls.get(APP_LANGUAGE);
    return lang === zhHK ? aliasI18n : alias
  } else {
    return alias
  }
}

// 设置多语言文本
export const setMultilingualText = (alias, aliasI18n) => {
  if (aliasI18n) {
    let titleI18n = i18n.t(aliasI18n);
    titleI18n !== aliasI18n && (alias = titleI18n) // 兜底，当远程数据没有对应ID多语言时用回原本的标题
  }
  return alias;
}

export function getScreenRatio () {
  var ratio = 0;
  var screen = window.screen;
  var ua = navigator.userAgent.toLowerCase();

  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf('msie')) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    ratio = window.outerWidth / window.innerWidth;
  }

  return ratio;
}

export function mergeRecursive(source, target) {
  for (var p in target) {
    try {
      if (target[p].constructor == Object) {
        source[p] = mergeRecursive(source[p], target[p]);
      } else {
        source[p] = target[p];
      }
    } catch (e) {
      source[p] = target[p];
    }
  }
  return source;
};
