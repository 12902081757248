<template>
  <div class="exception" ref="fdInnerScoll" :style="{ height: height }">
    <div class="imgBlock">
      <div class="imgEle" :style="{ backgroundImage: `url(${config[type].img})` }">
      </div>
    </div>
    <div class="content">
      <h1>{{ config[type].title }}</h1>
      <div class="desc">{{ setMultilingualText(config[type].desc, config[type].descI18n) }}</div>
      <div class="actions">
        <a-button type="primary" @click="handleToHome">{{ $t("exception.back.home") }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addEditDetailScroll } from "@/mixins/addEditDetailScroll"
import types from "./type"
import { setMultilingualText } from "@/utils/util"
import i18n from "@/locales";
export default {
  name: "Exception",
  mixins: [addEditDetailScroll],
  props: {
    type: {
      type: String,
      default: "404"
    }
  },
  data() {
    return {
      config: types,
      height: ""
    }
  },
  methods: {
    setMultilingualText,
    handleToHome() {
      this.$router.push("/")
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.fdContentHeight2 + "px";
    })
  }
}

</script>
<style lang="less">
@import "~ant-design-vue/lib/style/index";

.exception {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  min-height: 500px;

  .imgBlock {
    display: flex;
    justify-content: center;
    flex: 0 0 62.5%;
    width: 100%;
    zoom: 1;

    &::before,
    &::after {
      content: ' ';
      display: table;
    }

    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
  }

  .imgEle {
    // float: right;
    width: 100%;
    max-width: 430px;
    // height: 360px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  .content {
    // flex: auto;
    text-align: center;

    h1 {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 45px;
      color: #364064;
      line-height: 45px;
    }

    .desc {
      margin-bottom: 16px;
      color: @text-color-secondary;
      font-size: 18px;
      color: #364064;
      line-height: 28px;
    }

    .actions {
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .exception {
    .imgBlock {
      padding-right: 88px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .exception {
    display: block;
    text-align: center;

    .imgBlock {
      margin: 0 auto 24px;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .exception {
    .imgBlock {
      margin-bottom: -24px;
      overflow: hidden;
    }
  }
}
</style>
