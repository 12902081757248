// import sysconfig from "@/config/defaultSettings"
// base 工程基础路由
export default [
  //   {
  //   path: "/device",
  //   name: "device",
  //   meta: {
  //     title: "设备管理",
  //     topMenuId: "device",
  //     keepAlive: true,
  //     subsystemId: sysconfig.baseSubsystemId
  //   },
  //   component: () => import(/* webpackChunkName: "myMenu" */ "@/components/layouts/BasicLayout.vue"),
  //   redirect: "/device",
  //   children: [
  //     {
  //       path: "/device:id",
  //       name: "device",
  //       meta: {
  //         title: "设备管理",
  //         titleI18n: "frame.menu.homePage",
  //         topMenuId: "3",
  //         keepAlive: true,
  //         subsystemId: sysconfig.baseSubsystemId
  //       },
  //       component: () => import(/* webpackChunkName: "myMenu" */ "@/views/device/Index.vue")
  //     }
  //   ]
  // },
  // {
  //   path: "/device",
  //   name: "device",
  //   meta: {
  //     title: "设备管理",
  //     titleI18n: "frame.menu.homePage",
  //     topMenuId: "myMenu",
  //     keepAlive: true,
  //     subsystemId: sysconfig.baseSubsystemId
  //   },
  //   component: () => import(/* webpackChunkName: "myMenu" */ "@/views/device/Index.vue")
  // }
]
