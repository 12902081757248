import Vue from "vue";
import { VERIFY_CODE, ACCESS_TOKEN } from "@/store/mutation-types";
import { isBlank } from "@/utils/dataType";
import api from "@/api";
// import { changeLangType } from "@/utils/util"
const {
  login,
  phoneLogin,
  byValidCodeLogin,
  getVerifyCode,
  getLoginUser,
  changePassword,
  queryLoginUserMenu,
  firstLogin
} = api;
// 格式化树形结构数据 适用于 vue-router
let topMenuId = "0";
let themecolor = ["#2f54eb", "#52c41a", "#faad14", "#13c2c2"];
function formatMenus(items) {
  let arr = [];
  for (let i = 0; i < items.length; i++) {
    let bgColor = themecolor[Math.floor(Math.random() * themecolor.length)]; // 菜单背景色，从主题色中随机取一个
    let {
      parentId,
      path,
      menuId,
      component,
      meta,
      aliasI18n,
      permissionList,
      children,
      hidden,
      menuSubsystemId
    } = items[i];
    if (parentId === 0) {
      // 顶级菜单
      topMenuId = menuId;
    }
    // if (permissionList.length && permissionList[0] === "*:*:*") {
    //   permissionList = [];
    // }

    let temp = {
      path,
      name: "m" + menuId,
      menuId,
      component,
      hidden,
      meta: {
        topMenuId,
        title: meta.title,
        titleI18n: aliasI18n || meta.titleI18n, // 多语言标识
        icon: meta.icon,
        permission: permissionList,
        iconSize: "30px",
        bgColor,
        iframeSrc: "",
        subsystemId: menuSubsystemId
      }
    };
    if (
      component === "/system/iframe/IFrame" ||
      component === "/base/iframe/IFrame"
    ) {
      // 外链组件
      temp.path = `/iframe/${menuId}`;
      temp.meta.iframeSrc =
        path.indexOf("http") !== -1
          ? path
          : window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? `:${window.location.port}` : "") +
          path;
    }
    if (children && children.length > 0) {
      let firstChild = children[0];
      if (firstChild.component !== "_blank") {
        // 由于该字段会导致路由重定向，外部链接会导致重定向报错，估排除
        temp.redirect = children[0].path;
      }
      temp.children = formatMenus(children);
    }
    arr.push(temp);
  }
  return arr;
}
// 抽取数据
let arr = [];
function filterMenus(items) {
  items &&
    items.forEach((item) => {
      arr = [];
      if (item.children && item.children.length > 0) {
        toFloorTwo(item.children);
      }
      item.children = arr;
    });
  return items;
}
// 将第三层以上的数据抽出放到第二层
function toFloorTwo(items) {
  items &&
    items.map((item) => {
      if (item.children && item.children.length > 0) {
        toFloorTwo(item.children);
      } else {
        arr.push(item);
      }
    });
}

const user = {
  state: {
    verifyCode: "",
    token: "",
    info: {},
    menuPermission: [], // 菜单权限
    passable: false, // 路由跳转前是否需要获取当前用户菜单
    curOrg: {}, // 当前租户信息
    appCenter: [], // 常用应用分类信息
    serviceCentreMenu: [] // 服务中心分类菜单
  },

  mutations: {
    [VERIFY_CODE]: (state, param) => {
      state.verifyCode = param;
    },
    SET_TOKEN: (state, param) => {
      state.token = param;
    },
    SET_INFO: (state, param) => {
      state.info = param;
    },
    SET_MENUPERMISSION: (state, data) => {
      state.menuPermission = data;
    },
    SET_PASSABLE: (state, data) => {
      state.passable = data;
    },
    SET_CURORG: (state, data) => {
      state.curOrg = data;
      state.curOrg['openI18n'] = true;// 开启国际化
    },
    SET_APPCENTER: (state, data) => {
      state.appCenter = data;
    },
    SET_SERVICECENTREMENU: (state, data) => {
      state.serviceCentreMenu = data;
    }
  },

  actions: {
    // 获取验证码
    async getVerifyCodeAction({ commit }) {
      const res = await getVerifyCode();
      if (res.data.code === 200) {
        commit(VERIFY_CODE, res.data.data);
      }
      return res;
    },

    // 登录
    async loginAction({ dispatch, commit }, params) {
      const res = await login(params);
      return res;
    },

    // 手机号登录
    async phoneLoginAction({ dispatch, commit }, params) {
      const res = await phoneLogin(params);
      if (res.code === 200) {
        Vue.ls.set(ACCESS_TOKEN, res.data, 7 * 24 * 60 * 60 * 1000);
        commit("SET_TOKEN", res.data);
        dispatch("loginUserAction");
      }
      return res;
    },

    // 获取登录用户信息
    async loginUserAction({ commit }) {
      const res = await getLoginUser();
      if (res.code === 200) {
        Vue.ls.set("LOGIN_USER_INFO", res.data.user, 7 * 24 * 60 * 60 * 1000);
        commit("SET_INFO", res.data.user);
        sessionStorage.removeItem("PAGE_TABS");
        // location.reload();
      } else {
        Vue.prototype.message.error(res.msg);
      }
      return res;
    },

    // 15天内首次登录
    async firstLoginAction({ dispatch, commit }, params) {
      const res = await firstLogin(params);
      return res;
    },

    // 账号+验证码登录
    async byValidCodeLoginAction({ dispatch, commit }, params) {
      const res = await byValidCodeLogin(params);
      if (res.code === 200) {
        Vue.ls.set(ACCESS_TOKEN, res.data, 7 * 24 * 60 * 60 * 1000);
        commit("SET_TOKEN", res.data);
        dispatch("loginUserAction");
      }
      return res;
    },

    // 获取用户菜单
    async getLoginUserMenuAction({ commit }) {
      const res = await queryLoginUserMenu();
      let o = { code: res.code, data: res.data };
      if (res.code === 200) {
        if (isBlank(res.data)) {
          res.data = [];
        }
        console.log("菜单", res.data);
        o.data = formatMenus(res.data); // 数据处理
        // 通过JSON.stringify改变对象指针，防止菜单树原有结构被修改
        o.list = filterMenus(JSON.parse(JSON.stringify(o.data)));
        commit("SET_MENUPERMISSION", JSON.parse(JSON.stringify(o.data)));
        commit("SET_APPCENTER", o.list);
        commit("SET_PASSABLE", true);
      }
      return o;
    },

    // 获取获取用户菜单 mock
    /* async permissionAction ({ commit }) {
      const res = await getPermission();
      if (res.code === 200) {
        commit("SET_PERMISSIONLIST", res.result.menu)
      }
      return res;
    }, */

    // 退出登录 { commit }
    Logout({ commit }) {
      return new Promise((resolve) => {
        Vue.ls.remove(ACCESS_TOKEN);
        Vue.ls.remove("LOGIN_USER_INFO");
        commit("SET_TOKEN", "");
        // commit("SET_INFO", {});
        commit("SET_MENUPERMISSION", []);
        commit("SET_APPCENTER", []);
        commit("SET_PASSABLE", false);
        commit("SET_CURROUTE_PER", []);

        resolve();
      });
    },

    // 修改登录密码
    async changePasswordAction({ dispatch }, params) {
      const res = await changePassword(params.formData, params.userId);
      if (res.code === 200) {
        dispatch("Logout");
      }
      return res;
    }
  }
};
export default user;
