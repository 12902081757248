import i18n from "@/locales"
import router, { resetRouter } from "@/router"
import store from "@/store"
import { getFileSrc, nprogress } from '@/utils'
import { setDocumentTitle } from '@/utils/domUtil'
import Vue from "vue"

// import api from "@/api"
import { generateDynamicRoutes } from "@/router/generateRoutes"
import { ACCESS_TOKEN } from "@/store/mutation-types"
import sysconfig from "@/config/defaultSettings"
import { Modal } from 'ant-design-vue';
import { getCacheRoute } from "./helper"

// 白名单

// 精准匹配
const whiteList = ["/login", "/register", "/orgRegister", "/open/auth/wx", "/auth/login", "/auto/login"];

// 模糊匹配
const whiteListLike = ["/chart/open/"];

function sortWhiteListLike (path, arr) {
  let b = false;
  for (let i = 0; i < arr.length; i++) {
    if (path.indexOf(arr[i]) >= 0) {
      b = true;
      break;
    }
  }
  return b;
}

// 处理路由子系统间跳转
function subsystemLink (to, from, next) {
  if (to.matched.length && process.env.VUE_APP_SUBSYSTEM_ID && to.meta.subsystemId !== sysconfig.baseSubsystemId && to.meta.subsystemId !== process.env.VUE_APP_SUBSYSTEM_ID && to.path !== "/") {
    // 不是刷新页面情况下，独立运行的子系统 且 不是 base 通用路由 且 跳转的路由页面不在当前子系统 且 不是首页
    next(false);
    nprogress.done();
    if (process.env.NODE_ENV === "production") {
      const subsystem = store.state.app.subsystems.find(item => item.id === to.meta.subsystemId);
      let prefix = "";
      if (subsystem && (subsystem.url !== "/" || subsystem.url !== "/home")) {
        prefix = subsystem.url;
      }
      window.location.href = (prefix + to.fullPath).replace("//", "/");
    } else {
      Modal.warning({
        title: '当前子系统无此页面!'
      });
    }
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  // subsystemLink(to, from, next);

  // console.log("to", to)
  let title = "";
  if (to.meta.titleI18n) {
    // 国际化多语言
    title = i18n.t(to.meta.titleI18n);
  } else {
    title = to.meta.customTitle || to.meta.title || "";
  }

  let { bigTitle, smallTitle, bigIcon, smallIcon } = store.state.app
  if (!(bigTitle || smallTitle || bigIcon || smallIcon)) {
    // let { data: loginConfig, code: loginConfigCode } = await api.loginConfig()

    // if (loginConfigCode === 200) {
    //   loginConfig.bigTitle && tenant.commit('SET_BIG_TITLE', loginConfig.bigTitle)
    //   loginConfig.smallTitle && tenant.commit('SET_SMALL_TITLE', loginConfig.smallTitle)
    //   loginConfig.bigIcon && tenant.commit('SET_BIG_ICON', loginConfig.bigIcon)
    //   loginConfig.smallIcon && tenant.commit('SET_SMALL_ICON', loginConfig.smallIcon)
    // }
  }

  // 获取应用配置的统一icon图标
  if (store.state.app.smallIcon) {
    let favicon = store.state.app.smallIcon
    favicon === "public/favicon.ico" && (favicon = "../../../public/favicon.ico")
    window.getFavicon = getFileSrc(favicon, "../../../public/favicon.ico")
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.setAttribute('type', 'image/x-icon')
    link.setAttribute('rel', 'shortcut icon')
    link.setAttribute('href', window.getFavicon)
    document.head.appendChild(link)
  }

  if (store.state.app.bigTitle) {
    setDocumentTitle(`${title} - ${store.state.app.bigTitle}`);
  } else {
    setDocumentTitle(`${title} - ${i18n.t('web.site.name')}`);
  }
  if (whiteList.indexOf(to.path) !== -1) {
    // 在免登录白名单，直接进入
    next();
  } else if (sortWhiteListLike(to.path, whiteListLike)) {
    next();
  } else {
    // 不在免登录白名单
    if (Vue.ls.get(ACCESS_TOKEN)) {
      // console.log("tenant.state.user", tenant.state.user)
      /* 有 token */
      if (!store.state.user.passable) {
        // 路由跳转前是否需要获取当前用户菜单
        store.dispatch("getLoginUserMenuAction").then(res => {
          if (res.code === 200) {
            const menus = res.data;
            // 应用池 - 子系统列表
            // tenant.dispatch("subsystemAction");
            // 生成路由
            const routes = generateDynamicRoutes(menus.concat(getCacheRoute()));
            // 更新路由
            store.dispatch("updateRoutesAction", routes).then(() => {
              // 重置路由
              resetRouter()
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRoutes)
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          }
        })
      } else {
        next()
      }
    } else {
      /* 无 token */
      // 跳转登录页面前参数记录上一个页面
      next({ path: "/login", query: { redirect: to.fullPath } })
    }
  }
})

router.afterEach((to, from) => {
  to.meta.permission && store.commit("SET_CURROUTE_PER", to.meta.permission)
})
