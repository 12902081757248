import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'
import global from './zh-CN/global'
import frame from './zh-CN/frame'

import user from './zh-CN/user'
import menus from './zh-CN/menus'
import backend from './zh-CN/backend'
import { loadLocaleMessages } from './event'

const components = {
  antLocale: antd,
  momentName: 'zh-CN',
  momentLocale: momentCN
}

const dictionaryData = loadLocaleMessages('zh-CN');


export default {
  ...components,
  ...global,
  ...frame,
  ...user,
  ...menus,
  ...backend,
  ...dictionaryData
}
