
 export function loadLocaleMessages(lang) {
    const locales = require.context('../scanLang', true, /[a-z0-9-]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
      const matched = key.match(/([a-z0-9-]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    let langDict = {};
    Object.keys(messages).map(fileKey => {
      Object.keys(messages[fileKey]).map(langType => {
        if(langType == lang){
          langDict = Object.assign(langDict,messages[fileKey][langType])
        }
      })
    })
    return langDict;
  }