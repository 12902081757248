import message from "@/components/resetAntdv/Message";
import i18n from "@/locales";
/**
 * 接口联调后提示内容显示处理
 * @method resMsg (t, m, c)
 * @for 所属类名
 * @param {String} t 提示类型
 * @param {String} m 系统提示内容
 * @param {String} c 自定义提示内容
 */
export function resMsg(t, m, c, f) {

  let localM = i18n.t(m) || m;

  let msg = localM || c; // 当系统未定义提示内容时自定义提示内容
  switch (t) {
    case "success":
      message.success(msg, f);
      break;
    case "error":
      message.error(msg);
      break;
    case "info":
      message.info(msg);
      break;
    case "warning":
      message.warning(msg);
      break;
    default:
      message.warning(msg);
  }
}
