export default {
  'frame.tabs.nav.close': '关闭标签',
  'frame.tabs.nav.close.current': '关闭当前标签',
  'frame.tabs.nav.close.left': '关闭左侧',
  'frame.tabs.nav.close.right': '关闭右侧',
  'frame.tabs.nav.close.all': '关闭全部',
  'frame.news.center': '消息中心',
  'frame.menu.all': '全部菜单',
  'frame.menu.search': '请输入关键词搜索',
  'frame.menu.workbench': '我的工作台',
  'frame.menu.homePage': '首页',
  'frame.welcome.you': '欢迎您',
  'frame.app.center': '应用中心',
  'frame.app.normal': '常用应用',
  'frame.my.app.normal': '我的常用应用',
  'frame.my.child.system': '我的子系统',
  'frame.menus.normal': '常用菜单',
  'frame.no.app': '暂无应用',
  'frame.no.menu': '暂无菜单',
  'frame.no.directory': '暂无目录',
  'frame.back.app.normal': '返回常用应用',
  'frame.my.message': '我的消息',
  'frame.msg.all': '全部',
  'frame.msg.task': '任务',
  'frame.msg.notice': '公告',
  'frame.msg.check.all': '全选',
  'frame.msg.read': '已读',
  'frame.msg.empty': '清空',
  'frame.msg.unread': '未读',
  'frame.msg.reply': '回复',
  'frame.msg.no.or.nownot': '未选择消息/暂无消息内容',
  'frame.http.status.code.400': '错误请求',
  'frame.http.status.code.401-1': '您的账号已在其他设备登录，如非本人操作请修改密码！',
  'frame.http.status.code.401-2': '登录过期，请重新登录！',
  'frame.http.status.code.403': '拒绝访问',
  'frame.http.status.code.404': '请求错误,未找到该资源',
  'frame.http.status.code.405': '请求方法未允许',
  'frame.http.status.code.408': '请求超时',
  'frame.http.status.code.500': '服务器端出错',
  'frame.http.status.code.501': '网络未实现',
  'frame.http.status.code.502': '网络错误',
  'frame.http.status.code.503': '服务不可用',
  'frame.http.status.code.504': '网关超时（504 Gateway Time-out），网站官方联系！',
  'frame.http.status.code.505': 'http版本不支持该请求',
  'frame.http.status.code.default': '连接错误',
  // 以下为新增内容，翻译时需要增加下方能用
  'frame.layout.fullscreen':'全屏',
}
