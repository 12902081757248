const types = {
  403: {
    img: require("./svg/403.svg"),
    title: "403",
    desc: "抱歉，你无权访问该页面",
    descI18n:'exception.desc.403'
  },
  404: {
    // img: require("./svg/404.svg"),
    img: require("./img/404.png"),
    title: "404",
    desc: "抱歉，你访问的页面不存在或仍在开发中",
    descI18n:'exception.desc.404'
  },
  500: {
    // img: require("./svg/500.svg"),
    img: require("./img/500.png"),
    title: "500",
    desc: "抱歉，服务器出错了",
    descI18n:'exception.desc.500'
  },
  999:{
    img: require("./img/404.png"),
    title: "",
    desc: "功能即将上线，敬请期待",
    descI18n:'exception.desc.999'
  }
}

export default types
