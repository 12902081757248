export default {
    'frame.mean-0'  : '平台管理',
	'frame.mean-1'  : '服务套餐',
	'frame.mean-2'  : '设备中心',
	'frame.mean-3'  : '系统管理',
	'frame.mean-4'  : '字典管理',
	'frame.mean-5'  : '消息公告',
	'frame.mean-6'  : '生产中心',
	'frame.mean-7'  : '运营中心',
	'frame.mean-8'  : '门店管理',
	'frame.mean-9'  : '基础管理',
	'frame.mean-10' : '生产管理',
	'frame.mean-11' : '库存管理',
	'frame.mean-12' : '员工管理',
	'frame.mean-13' : '设备监控',
	'frame.mean-14' : '售后中心',
	'frame.mean-15' : '售后工单',
	'frame.mean-16' : '租户管理',
	'frame.mean-17' : '人员管理',
	'frame.mean-18' : '套餐定义',
	'frame.mean-19' : '菜单管理',
	'frame.mean-20' : '设备管理',
	'frame.mean-21' : '软件升级管理',
	'frame.mean-22' : '软件包管理',
	'frame.mean-23' : '操作日志',
	'frame.mean-24' : '调试日志',
	'frame.mean-25' : '饮品管理',
	'frame.mean-26' : '原料管理',
	'frame.mean-27' : '基础信息',
	'frame.mean-28' : '设备管理',
	'frame.mean-29' : '设备管理',
	'frame.mean-30' : '数据统计',
	'frame.mean-31' : '清洗管理',
	'frame.mean-32' : '活动管理',
	'frame.mean-33' : '门店管理',
	'frame.mean-34' : '智能开店',
	'frame.mean-35' : '菜单管理',
	'frame.mean-36' : '角色管理',
	'frame.mean-37' : '人员管理',
	'frame.mean-38' : '帐号管理',
	'frame.mean-39' : '字典管理',
	'frame.mean-40' : '操作日志',
	'frame.mean-41' : '工单管理',
	'frame.mean-42' : '料仓管理',
	'frame.mean-43' : '排班管理',
	'frame.mean-44' : '库存管理',
	'frame.mean-45' : '采购计划',
	'frame.mean-46' : '销售预测',
	'frame.mean-47' : '设备查询',
	'frame.mean-48' : '设备新增',
	'frame.mean-49' : '设备修改',
	'frame.mean-50' : '设备删除',
	'frame.mean-51' : '设备导出',
	'frame.mean-52' : '设备故障日志',
	'frame.mean-53' : '设备故障日志查询',
	'frame.mean-54' : '设备故障日志新增',
	'frame.mean-55' : '设备故障日志修改',
	'frame.mean-56' : '设备故障日志删除',
	'frame.mean-57' : '设备故障日志导出',
	'frame.mean-58' : '设备故障日志详情',
	'frame.mean-59' : '设备故障日志详情查询',
	'frame.mean-60' : '设备故障日志详情新增',
	'frame.mean-61' : '设备故障日志详情修改',
	'frame.mean-62' : '设备故障日志详情删除',
	'frame.mean-63' : '设备故障日志详情导出',
	'frame.mean-64' : '售后工单',
	'frame.mean-65' : '设备查询',
	'frame.mean-66' : '设备新增',
	'frame.mean-67' : '设备修改',
	'frame.mean-68' : '设备删除',
	'frame.mean-69' : '设备导出',
	'frame.mean-70' : '设备故障日志',
	'frame.mean-71' : '设备故障日志查询',
	'frame.mean-72' : '设备故障日志新增',
	'frame.mean-73' : '设备故障日志修改',
	'frame.mean-74' : '设备故障日志删除',
	'frame.mean-75' : '设备故障日志导出',
	'frame.mean-76' : '设备故障日志详情',
	'frame.mean-77' : '设备故障日志详情查询',
	'frame.mean-78' : '设备故障日志详情新增',
	'frame.mean-79' : '设备故障日志详情修改',
	'frame.mean-80' : '设备故障日志详情删除',
	'frame.mean-81' : '设备故障日志详情导出',
	'frame.mean-82' : '角色管理',
	'frame.mean-83' : '门店工单',
	'frame.mean-84' : '调试日志记录查询',
	'frame.mean-85' : '调试日志记录下载',
	'frame.mean-86' : '调试日志记录修改',
	'frame.mean-87' : '调试日志记录删除',
	'frame.mean-88' : '调试日志记录导出',
	'frame.mean-89' : '软件升级查询',
	'frame.mean-90' : '软件升级新增',
	'frame.mean-91' : '软件升级修改',
	'frame.mean-92' : '软件升级删除',
	'frame.mean-93' : '软件升级导出',
	'frame.mean-94' : '软件包管理查询',
	'frame.mean-95' : '软件包管理新增',
	'frame.mean-96' : '软件包管理修改',
	'frame.mean-97' : '软件包管理删除',
	'frame.mean-98' : '软件包管理导出',
	'frame.mean-99' : '操作日志查询',
	'frame.mean-100': '操作日志新增',
	'frame.mean-101': '操作日志修改',
	'frame.mean-102': '操作日志删除',
	'frame.mean-103': '操作日志导出',
	'frame.mean-104': '人员管理列表',
	'frame.mean-105': '人员管理查询',
	'frame.mean-106': '人员管理新增',
	'frame.mean-107': '人员管理修改',
	'frame.mean-108': '人员管理删除',
	'frame.mean-109': '人员管理导出',
	'frame.mean-110': '人员管理',
	'frame.mean-111': '人员管理查询',
	'frame.mean-112': '人员管理新增',
	'frame.mean-113': '人员管理修改',
	'frame.mean-114': '人员管理删除',
	'frame.mean-115': '人员管理导出',
	'frame.mean-116': '角色管理列表',
	'frame.mean-117': '角色管理查询',
	'frame.mean-118': '角色管理新增',
	'frame.mean-119': '角色管理修改',
	'frame.mean-120': '角色管理删除',
	'frame.mean-121': '角色管理导出',
	'frame.mean-122': '角色管理',
	'frame.mean-123': '角色管理查询',
	'frame.mean-124': '角色管理新增',
	'frame.mean-125': '角色管理修改',
	'frame.mean-126': '角色管理删除',
	'frame.mean-127': '角色管理导出',
	'frame.mean-128': '订单对接',
	'frame.mean-129': '设备配方',
	'frame.mean-130': '设备型号',
	'frame.mean-131': '消息管理',
	'frame.mean-132': '自定义CIP清洗',
	'frame.mean-133': '自定义CIP清洗删除',
	'frame.mean-134': '自定义CIP清洗下发',
	'frame.mean-135': '操作日志',
	'frame.mean-136': '软件升级',
	'frame.mean-137': '软件版本',
	'frame.mean-138': '软件包',
	'frame.mean-139': '自定义料仓',
  'frame.mean-140': '配方方案'
}
